import * as z from 'zod'
import { StringToDate } from '@digital-magic/react-common/lib/utils/zod'
import { DisplayName, EmailAddress, FreeFormText, Language, PhoneNumber, page } from '@api/endpoints/types'

export const CustomerMessageId = z.string().uuid()
export type CustomerMessageId = z.infer<typeof CustomerMessageId>

export const SendMessageRequest = z.object({
  language: Language,
  displayName: DisplayName,
  emailAddress: EmailAddress,
  phoneNumber: PhoneNumber,
  message: FreeFormText
})
export type SendMessageRequest = z.infer<typeof SendMessageRequest>

export const CustomerMessage = SendMessageRequest.extend({
  id: CustomerMessageId,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type CustomerMessage = z.infer<typeof CustomerMessage>

export const CustomerMessageList = page(CustomerMessage)
export type CustomerMessageList = z.infer<typeof CustomerMessageList>
