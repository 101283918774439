/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useApiMutation, useApiQuery } from '@digital-magic/react-common/lib/api/hooks'
import { apiBaseUrl } from '@constants/configuration'
import { ApiMutationOpts, ApiQueryOpts } from '@api/types'
import { callOnly, receiveOnly, sendOnly } from '@api/utils'
import { CustomerMessageId, CustomerMessageList, SendMessageRequest } from './types'

const messagesUrl = `${apiBaseUrl}/messages`
const messageUrl = (messageId: CustomerMessageId): string => `${messagesUrl}/${messageId}`

const queryKeys = {
  findMessagesAll: ['findLatestMessages'],
  findMessagesPage: (pageNr: number) => ['findLatestMessages', pageNr]
}

export const useSendMessage = (opts?: ApiMutationOpts<void, SendMessageRequest>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendOnly({
        method: 'post',
        url: messagesUrl,
        requestSchema: SendMessageRequest,
        data
      }),
    invalidateQueries: [queryKeys.findMessagesAll],
    ...opts
  })

export const useGetMessagesPage = (pageNr: number, opts?: ApiQueryOpts<CustomerMessageList>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: messagesUrl,
        params: { pageNr },
        responseSchema: CustomerMessageList
      }),
    queryKey: queryKeys.findMessagesPage(pageNr),
    ...opts
  })

export const useDeleteMessage = (opts?: ApiMutationOpts<void, CustomerMessageId>) =>
  useApiMutation({
    mutationFn: (customerMessageId) =>
      callOnly({
        method: 'delete',
        url: messageUrl(customerMessageId)
      }),
    invalidateQueries: [queryKeys.findMessagesAll],
    ...opts
  })
